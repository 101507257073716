.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* for sidebar css */

.Main-menu {
  height: 90vh;
  background-color: #494D55 !important;
  color: #fff !important;
  ;
}

@media (min-width:1025px) and (max-width:1920px) {
  .Main-menu {
    /* width: 12.5vw; */
  }
}

.child-menu {
  padding: 8px 0px 8px 18px;
}

.main-active-light {
  /* color: #039CFD !important; */
  color: #fff !important;
  /* background-color: #F8FAFB; */
  background-color: #31343A;
  /* width: 100%; */
  /* margin-right: 10px; */
}

.main-inactive {
  color: #fff;
}

.pro-icon-wrapper {
  background: none !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background: none !important;
}

.pro-sidebar {
  /* width: 227px !important; */
  min-width: 0px !important;
  /* border: 1px solid red; */
}

.main-box-sidebar {
  width: 245px !important;
}

.main-content {
  width: 245px;
}

/* for navbar css */
.search {
  width: 300px;
  height: 34px;
  padding: 7px 0px 7px 40px;
  border: 1px solid #E6EAEF;
  border-radius: 8px;
  /* search-light */
  background: url(./assets/images/search-light.png)no-repeat;
  font-size: 16px;
  background-size: 15px;
  background-position: 12px 16px;
}

.pro-sidebar .pro-menu {
  background: #fff;
}

.main {
  background-color: none;
  padding-left: 15px;
  border-radius: 8px;
  height: 60px;
  width: 50%;
  background: "#f0f0f1"
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  background: #fff;
}

input[type='file'] {
  color: rgba(0, 0, 0, 0)
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding: 0px !important;
}